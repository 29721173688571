import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"

import doctorHospital from "src/assets/img/icons/doctor-hospital.svg"

const Pricing = () => (
  <Layout>
    <section className="Pricing u-bgLightestGrey">
      <Seo
        title="Carebit: Pricing"
        pathname="/pricing"
        description="Simple, flat-rate pricing based on the number of doctors."
      />
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} md={{ size: 10, offset: 1 }}>
            <div className="u-textCenter">
              <h1>One simple plan with all you need</h1>
              <p className="u-midGrey">
                There aren’t any pricing gimmicks with Carebit. Everything you
                need is included in one simple monthly price per doctor.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 6, offset: 3 }} md={{ size: 10, offset: 1 }}>
            <section>
              <div className="Card u-textCenter">
                <img
                  alt="Carebit - Doctor illustration"
                  src={doctorHospital}
                  style={{ width: "150px", marginBottom: "20px" }}
                />
                <h3>£149</h3>
                <h3 className="subtext">+VAT per doctor/month</h3>
                <ul className="u-listStyleNone Pricing-featuresGrid">
                  <li>Industry-leading encryption & security</li>
                  <li>
                    Unlimited patients, bookings, notes, test results & letters
                  </li>
                  <li>Unlimited staff members</li>
                  <li>Video calls</li>
                  <li>Healthcode and Xero integration</li>
                  <li>
                    Let patients make online bookings 24/7 at their convenience
                  </li>
                  <li>
                    Online payments facility (from 2% + 20p per transaction)
                  </li>
                  <li>Automated booking emails</li>
                  <li>Automatically email out customised, branded letters</li>
                  <li>Warning emails for unpaid bookings</li>
                  <li>Expert advice on running & marketing your practice</li>
                </ul>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default Pricing
